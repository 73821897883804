<template>
  <div>
    <div class="carousel-example vx-row " :style="{'margin-top': $store.state.windowWidth>767?'-20px':'inherit', 'border-radius':''}">
      <swiper ref="mySwiper" class="rounded-lg" v-if="slider" :options="swiperOption">
        <swiper-slide :key="i" v-for="(s,i) in slider.slider.slides">
          <div class="h_slide-container ">
            <img class="responsive" :src="s.file.path" alt="banner">
            <div class="h_slide-content text-center">
              <h1 style="font-size: 3vw;" v-if="s.caption_1">{{s.caption_1}}</h1>
              <h3 style="font-size: 2vw;" v-if="s.caption_2">{{s.caption_2}}</h3>
              <div v-if="s.caption_3" class="text">
                <p style="font-size: 1.5vw;">{{s.caption_3}}</p>
              </div>
              <div v-if="s.call_to_action_text" class="mt1 text-center">
                <router-link :to="s.call_to_action_url" :target="s.open_in_new_window?'_blank':'self'"><vs-button :size="$store.state.windowWidth < 786? 'small':''" color="primary" type="filled">{{s.call_to_action_text}}</vs-button></router-link>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
    <div v-if="flash.length>0" class="vx-row">
      <div class="header-title">
        <h3>Flash Sale</h3>
        <span class="show-all bg-primary text-white shadow-drop" @click="$router.push('flash-sales')">Show all</span>
      </div>
      <div class="vx-col w-full bg-primary">
        <vue-countdown :transform="transform" :time="flash_remain">
          <template slot-scope="props">
            <div class="text-center">
              <p class="text-white p-4"><span class="text-white">Ending In </span> <span class="rounded bg-white text-dark p-2 m-2 font-semibold justify-around" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.hours }}</span> : <span class="rounded bg-white text-dark font-semibold p-2 m-2 justify-around" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.minutes }}</span> : <span class="rounded bg-white text-dark p-2 m-2 font-semibold justify-around" :style="{background: `rgba(var(--vs-warning),.15)`}" >{{ props.seconds }}</span></p>
            </div>
          </template>
        </vue-countdown>
      </div>
      <vx-card v-if="$store.state.windowWidth<768" class="vx-col w-full p-0 mt-1">
        <div  class="vx-row">
          <div class="vx-col flex w-full xl:w-1/3  lg:w-1/2 md:w-1/2">
            <div class="vx-col w-1/2" style="border-right: 1px solid rgb(235, 235, 235)">
              <flash-grid :item="flash[0]"></flash-grid>
            </div>
            <div v-if="flash.length>1" class="vx-col w-1/2 p-1">
              <flash-row v-for="(f,i) in flash.slice(1,4)" :item="f" :key="i"></flash-row>
            </div>
          </div>
        </div>
      </vx-card>
      <div v-else class="items-grid-view vx-col w-full mt-3 match-height">
        <div class="vx-row">
          <div class="vx-col lg:w-1/6 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="item in flash.slice(0,6)" :key="item.id">

            <item-grid-view :item="item"></item-grid-view>

          </div>
        </div>

      </div>

    </div>
    <div class="vx-row">
      <div class="header-title">
        <h3>Categories</h3>
        <span class="show-all bg-primary text-white shadow-drop" @click="$router.push('/categories')">Show all</span>
      </div>
      <div class="vx-col category-grid lg:w-1/6 sm:w-1/3 w-1/3" v-for="item in filtered_cat" :key="item.id">
        <category :category="item">
        </category>
      </div>
    </div>
    <div v-if="product_carousel.enabled" class="items-grid-view vx-row match-height">
      <div class="header-title">
        <h3>{{product_carousel.label}}</h3>
      </div>
      <div class="vx-col lg:w-1/6 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="item in product_carousel.products" :key="item.id">
        <item-grid-view :item="item">
        </item-grid-view>
      </div>
    </div>
    <div v-if="recent_products.enabled" class="items-grid-view vx-row match-height">
      <div class="header-title">
        <h3>{{recent_products.label}}</h3>
      </div>
      <div class="vx-col lg:w-1/6 sm:w-1/3 w-1/2" style="padding: 0 0.2rem;" v-for="item in recent_products.products" :key="item.id">
        <item-grid-view :item="item">
        </item-grid-view>
      </div>
    </div>
  </div>
</template>
<script>
  import 'swiper/dist/css/swiper.min.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import Category from "./components/Category";
  import VueCountdown from '@chenfengyuan/vue-countdown';
  import FlashGrid from "./components/FlashGrid";
  import FlashRow from "./components/FlashRow";
  export default {
    data() {
      return {
        swiperOption: {
          speed:1000,
          spaceBetween: 30,
          centeredSlides: true,
          autoplay: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }
        },
        slider:{
          slider:{
            autoplay_speed:5000,
          }
        },
        categories:[],
        show_all_cat:false,
        product_carousel: {
          enabled:false,
          label:'Loading',
          products:[]
        },
        flash:[],
        recent_products: {
          enabled:false,
          label:'Loading',
          products:[]
        },
      }
    },
    methods:{
      transform(props) {
        Object.entries(props).forEach(([key, value]) => {
          // Adds leading zero
          const digits = value < 10 ? `0${value}` : value;

          props[key] = `${digits}`;
        });

        return props;
      },
      update(){
        this.axios.get('homeSlider').then(({data})=>{
          this.slider = data;
          if (this.slider.slider.autoplay) {
            this.swiper.autoplay.delay = 50000;
            this.swiper.autoplay.start({
              delay:50000,
            });
          }

        });
        this.axios.get('/home').then(res =>{
          if (res.status==200){
            this.$store.commit('UPDATE_CATEGORY',res.data.categories);
            this.categories = res.data.categories;
            this.product_carousel = res.data.product_carousel;
            this.recent_products = res.data.recent_products;
            this.flash = res.data.flash;
          }
        })
      }
    },
    computed: {
      flash_remain(){
        if (this.flash.length > 0){
          var end_date = Date.parse(this.flash[0].special_price_end);
          var today = new Date();
          return end_date-today;
        }
        else return 0;
      },
      filtered_cat(){
        if (this.show_all_cat)
          return this.categories;
        return this.categories.slice(0, 12);
      },
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    mounted(){
      this.update();
    },
      components: {
        FlashGrid,
        FlashRow,
        VueCountdown,
        Category,
        swiper,
        swiperSlide,
        ItemGridView: () => import("./components/ItemGridView.vue"),
    }
    }
</script>
<style>
  .h_slide-container {
    position: relative;
  }
  .category-grid {
    padding: 0.3rem 0.5rem !important;
  }
  @media (max-width: 767px) {
    .category-grid {
      padding: 0rem 0.15rem !important;
    }
  }
  .h_slide-content {
    width: 90%;
    position: fixed;
    top: 0px;
    margin: 5vw 1vw 1vw 5vw;
  }
  .search-tab-filter {
    padding: .5rem 1rem;
    border-radius: 25px;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    background: #fff;
    cursor: pointer;
  }
</style>
