
<template>
  <div>
    <div class="grid-view-item flex overflow-hidden" v-on="$listeners">
      <template>

        <!-- ITEM IMAGE -->
        <div v-bind:class="{'out-of-stock':!item.in_stock}" class="item-img-container bg-white w-2/5 items-center justify-center cursor-pointer" @click="navigate_to_detail_view">
          <img :src="base_image[0].path" :alt="item.name" class="grid-view-img p-1">
          <small  v-if="!item.in_stock"><strong class="text-danger">Sold Out</strong></small>
        </div>
        <div class="item-details w-3/5 px-1">
          <!-- RATING & PRICE -->
          <div class="flex justify-between items-center">
            <div class="text-small" truncate-two-line v-if="special_price">
              <h6> <span class="text-primary">{{special_price.price | currency}} </span> </h6>
              <small> <span class="old_price">{{item.price.amount | currency}}</span>, {{special_price.percentage}}%</small>
            </div>
            <h6 v-else  class="text-primary truncate-two-line"><span>{{item.price.amount | currency}}</span> </h6>
          </div>

        </div>

        <!-- SLOT: ACTION BUTTONS -->
        <template v-if="$route.path != '/'">
          <div class="flex flex-wrap">
            <div :id="'quick_order_'+item.id"
                 :class="{'bg-primary':!isInCart(item.id), 'bg-success':isInCart(item.id)}"
                 class="item-view-secondary-action-btn vs-con-loading__container bg-primary p-3 mt-1 flex flex-grow items-center justify-center text-white cursor-pointer"
                 @click="cartButtonClicked(item)">
              <feather-icon icon="ShoppingBagIcon" svgClasses="h-4 w-4" />

              <span class="text-sm font-semibold ml-2" v-if="isInCart(item.id)">VIEW IN CART</span>
              <span class="text-sm font-semibold ml-2" v-else>Quick Order</span>
            </div>
          </div>
        </template>

      </template>
    </div>
    <vs-prompt
      @cancel="phone=''"
      @accept="saveNumber"
      title="Quick Order"
      accept-text="Ok"
      :is-valid="validNumber"
      :active.sync="activePrompt">
      <div class="con-exemple-prompt">
        <span>Enter you mobile number</span>
        <vs-input placeholder="Mobile number" type="number" vs-placeholder="Mobile number" v-model="phone" class="mt-3 w-full" />
        <vs-alert :active="!validNumber" color="danger" vs-icon="new_releases" >
          Please enter 11 digit valid number
        </vs-alert>
      </div>
    </vs-prompt>
  </div>

  <!--  </div>
</div> -->
</template>

<script>
  import StarRating from 'vue-star-rating'
  export default{
    name: "FlashRow",
    components:{
      StarRating
    },
    props: {
      item: {
        type: Object,
        required: true
      },
    },
    data(){
      return {
        activePrompt:false,
        quickOrderId:'',
        phone:'',
      }
    },
    computed:{
      validNumber(){
        return (this.phone && this.phone.length == 11);
      },
      isInCart() {
        return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId)
      },
      base_image(){
        return this.item.files.filter(function (f) {
          return f.pivot.zone ==='base_image'
        })
      },
      isInWishList() {
        return (itemId) => this.$store.getters['eCommerce/isInWishList'](itemId)
      },
      special_price(){
        if (this.item.special_price_end){
          var end_date = Date.parse(this.item.special_price_end);
          var today = new Date();
          var percentage = ((this.item.special_price.amount-this.item.price.amount)/this.item.price.amount)*100;
          if (end_date>today){
            return {
              price:this.item.special_price.amount,
              percentage: percentage.toFixed(0)
            };
          }
          return false;
        }
        return false;
      }

    },
    methods: {
      navigate_to_detail_view() {
        this.$router.push({name: 'product_view', params: {slug: this.item.slug }})
          .catch(() => {})
      },
      toggleItemInWishList(item) {
        this.$store.dispatch('eCommerce/toggleItemInWishList', item)
      },
      saveNumber(){
        if (this.phone && this.phone.length == 11){
          this.$store.commit('UPDATE_USER', {phone: this.phone});
          this.addQuickOrder();
        }

      },
      addQuickOrder(){
        if (!this.$store.state.user.phone){
          this.activePrompt = true;
        }else{
          this.$vs.loading({
            container: "#quick_order_"+this.quickOrderId,
            scale: 0.45
          });
          this.axios.post('quick-orders', {product_id:this.quickOrderId, phone:this.$store.state.user.phone}).then(({data})=>{
            this.$vs.loading.close("#quick_order_"+this.quickOrderId+" > .con-vs-loading")
            this.$vs.notify({
              title: 'Success',
              text:data.message,
              color:'success',
            });
          })
        }

      },
      cartButtonClicked(item) {
        if(this.isInCart(item.id)) this.$root.$emit('showCart')
        else  {
          this.quickOrderId = item.id;
          this.addQuickOrder();
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .grid-view-item {
    .grid-view-img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      transition: .35s;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 4px 25px 0px rgba(0,0,0,.25);

      .grid-view-img{
        opacity: 0.9;
      }
    }
  }
  .old_price {
    text-decoration: line-through ;
  }
  .truncate-two-line {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: pre-wrap;
    line-height: 18px;
    width: 100%;
    font-size: 14px;
    height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .out-of-stock img {
    opacity: .4;
  }
  .out-of-stock h2 {
    position: absolute;
  }
</style>
